import * as React from 'react';
import Button from '@mui/material/Button';
import baseballImage from './Baseball.svg';
import './Navbar.css';

export default function Navbar() {
    return (
        <div className="navbar" position="static">
            <div className="logo">
                <img src={baseballImage} className="App-logo" alt="logo" />
                <p>MLB Gameday Scoreboard</p>
            </div>
            <div className="links">
                <Button href="https://www.linkedin.com/in/claycotton/" color="inherit">
                    <p className="button-text">LinkedIn</p>
                </Button>
                <Button href="https://github.com/Claycot" color="inherit">
                    <p className="button-text">GitHub</p>
                </Button>
            </div>
        </div>
    );
}