export async function fetchGameList(setData) {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}/api/games/info/initial`);
        const data = await response.json();
        setData(data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export function getScore(item) {
    let score = {
        "away": null,
        "home": null
    };
    switch (item.state.status.general) {
        case "Preview":
            // leave the score null
            break;
        case "Live":
            // update the score
            score.away = item.teams.away.score.toString();
            score.home = item.teams.home.score.toString();
            break;
        case "Final":
            if (item.state.status.detailed !== "Postponed") {
                // update the score
                score.away = item.teams.away.score.toString();
                score.home = item.teams.home.score.toString();
            }
            break;
        default:
            // leave the score null
            break;
    };
    return score;
}

export function getInning(item) {
    let inning = {
        "top_bottom": null,
        "number": null
    };
    switch (item.state.status.general) {
        case "Preview":
            // leave the inning null
            break;
        case "Live":
            // update the inning
            inning.top_bottom = item.state.inning.top_bottom;
            inning.number = item.state.inning.number;
            break;
        case "Final":
            // update the inning
            inning.number = "f";
            break;
        default:
            // leave the inning null
            break;
    };
    return inning;
}