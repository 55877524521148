import React, { useState, useEffect } from 'react';
import Scoreboard from './Scoreboard';
import { fetchGameList } from './utils';
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';

function App() {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    fetchGameList(setResponse); // Initial fetch

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(() => {
      fetchGameList(setResponse);
    }, 5000); // 5 seconds in milliseconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <Navbar />
      <h3>
        {response && response.metadata ? (
          <div>
            <span>Game Date:&nbsp;{new Date(response.metadata.timestamp).toLocaleDateString()}</span>
            <span>&nbsp;·&nbsp;</span>
            <span>Refreshed:&nbsp;{new Date(response.metadata.timestamp).toLocaleTimeString()}</span>
          </div>
        ) : null}
      </h3>
      {response && response.data ? <Scoreboard data={response.data} /> : "Loading..."}
      <Footer />
    </div>
  );
}

export default App;