import React, { useState, useEffect } from 'react';
import Gameboard from './Gameboard';
import './Scoreboard.css';

function Scoreboard({ data }) {
  const [gameboardWidth, setGameboardWidth] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      // Get the window's inner width
      const windowWidth = window.innerWidth;
      let boardWidth;

      // If the window is lte than 768px (mobile), use the whole width
      if (windowWidth <= 768) {
        boardWidth = windowWidth * 0.90;
      }
      // Otherwise, put 2 boards side-by-side
      else {
        boardWidth = windowWidth * 0.45;
      }

      // Set the value
      setGameboardWidth(boardWidth);
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run effect only once on mount

  return (
    <div className="scoreboard">
      {!data ? "Loading..." :
        data.map((item, index) => (
          <Gameboard key={index} item={item} width={gameboardWidth} />
        ))
      }
    </div>
  );
}

export default Scoreboard;